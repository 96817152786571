@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto/Roboto-Thin.ttf');
  font-weight: 100;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  font-size: 13px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::placeholder {
  font-size: 13px;
}

input {
  accent-color: #990000;
}